*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container{

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: aqua;
    padding: 30px;
    border-radius: 10px;
}

.form-container form{
    display: flex;
    flex-direction: column;
    gap:10px;

   
}

.form-divs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

form button{
    margin-top: 10px;
    transition: 0.5s;   
}

form button:hover{
    background-color: red;
    color: white;
    border: none;
    outline: none;
}