*{

    margin:0;
    padding: 0;
    box-sizing: border-box;
}

.miilkile-contaner{

    display: flex;
    margin: auto;
    flex-wrap: wrap;
    gap: 10px;
    background-color: blueviolet;
}

.milkile-form{
 
    display: flex;
    margin-top: 100px;
   
    background-color: yellow;
}
.milkile-form form{
    
    display: flex;
    

    
}
.milkile-form form button{

    padding: 0 30px;
    margin-left: 4px;
    color:red;
}

.milkile-form form input{

flex-wrap: wrap;
}
.Listmilkile{

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    gap:20px;
    flex-direction: column;
}
table{
       margin-top: 100px;
       display: block;
       border: 1px solid black;
}

thead th {
    width: fit-content;
    background-color: lightblue;
}

.td-data{
    border: 1px solid black;
    padding: 0 20px;
    width: 150px;
    
}


@media screen and (max-width:900px) {

    table{
       display: grid;    
       width: fit-content;  
       margin: 50px pauto;
      
    }
    .td-isticmal{
        display: none;
        background-color: blue;
    }
    .td-data{
        width: fit-content;
       
    }
    table th {
      
        flex: 1;
    }
    
    .miilkile-contaner{
        width: 40%;
        background-color: white;
        margin: 30px;
        padding: 10px;
        
    }
    
    .milkile-form{
        display:flex;
        margin: auto;
        
    }
    .milkile-form form{
        margin: auto;
        flex-wrap: wrap;
    }
    .milkile-form form input{
        width: 300px;
    }
    
}

.milkile-total{
    margin-top: 10px;
    border-top:1px dashed red;
 
}