*{
margin: 0;
padding: 0;
box-sizing: border-box;
}

.sandanve-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

}
.sanadle-table{
   
        
    margin: 10px auto;
  
    column-span: all;
   
 
    
}
.sanadle-table tr td {
    border: 1px solid blue;
    
}

.sanadle-head tr:first-child {
    border: none;
    background-color: lightblue;
    column-span: all;
    border:none;
}

.Sanadanbe-total{

    border-top: 2px dashed red;
    display: flex;

    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 20%;
}