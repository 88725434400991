*{

    margin:0;
    padding: 0;
    box-sizing: border-box;
}

.miilkile-contaner{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: blueviolet;
}

.milkile-form{

    display: flex;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}
.milkile-form form{
    
    display: flex;
    height: 30px;
    
}
.milkile-form form button{

    padding: 0 30px;
    margin-left: 4px;
    color:red;
}

.milkile-form form input{

width:130px
}
.Listmilkile{

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    gap:20px;
    flex-direction: column;
}
table{
       
       display: block;
       border: 1px solid black;
}
thead tr td {
    border: 1px solid green;
}
.td-data{
    border: 1px solid black;
    padding: 0 20px;
    width: 150px;
    
}

.milkile-total{
    margin-top: 10px;
    border-top:1px dashed red;
 
}