*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rasulmal-container{




    display: flex;
    flex-direction: column;
   
    padding: 20px;
    background-color: blue;
    width: 100%;
}

.form-rasulmal{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    background-color: greenyellow;
    
}
.form-rasulmal form {
  display: flex;
  margin: 20px auto;
  gap:10px;
  outline: 1px solid black;
  flex-wrap: wrap;
  
}
.form-card {
    background:green;
    border-radius:10px;
    padding:10px;
    
    width: 50%;
    height: fit-content;
    flex:1;

}
.form-column{

    display: flex;
    flex-direction: column;
    background-color: red;
    gap:10px;
    padding: 10px;
    
}
.form-row{
    flex:1;
    outline: 1px solid white;
    padding: 10px;
}
.form-rasulmal form input{

    width: 80%;
}
.form-rasulmal table thead th {

   border-left:1px solid green;
}
.form-rasulmal table tr td{
    border:1px solid red;
}

.net-profit{
     border-radius:10px;
     background:blue;
     padding:10px;
     flex-wrap:wrap;
}

.net-profit input{
    flex: 1;
    width: 60%;
}

@media only screen and (max-width:300px){
    

    .rasulmal-container{
        width: 100%;
        background-color: rgb(92, 50, 74);
        margin: auto;
    }

    .net-profit input{
        flex: 1;
        width:fit-content;
    }

    .form-card {
        flex:1;
        flex-wrap: nowrap;
    
    }
}