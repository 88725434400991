*{

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    width: 100%;
    height: 100%;
}
.Listmilkile{
 display: flex;
 align-items: center;
 
 justify-content: space-around;
 
 margin: 100px auto;
}

.log-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    background-color: aquamarine;
}

.footer{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    gap:20px;
}

.saxiixa{
    display: flex;
    gap: 20px;
}