*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vmin;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    }

.App {
  width: 90%;
  
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
  margin: auto;
  
}

@media only screen and (max-width: 768px) {
.App{
  
  padding: 10px;
}
}
