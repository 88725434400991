*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .App {
    width: 80vw;
    
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: 1px solid green;
    margin: 20px auto;
  }
  